jQuery(document).ready(function($){

    $('.js-copy').on('click', function() {
      let $btn = $(this);
      let $elem = $btn.siblings('[data-js-copy]');

      if (!$elem.length) {
          return;
      }

      let content = $elem.text();
      navigator.clipboard.writeText(content);

      $btn.removeClass('icon-copy').addClass('icon-check');

      setTimeout(() => {
        $btn.removeClass('icon-check').addClass('icon-copy');
      }, 1000);

    });

    $('[data-copy-url]').on('click', function() {
        let url = window.location.href;
        let $icon = $(this).find('i');

        navigator.clipboard.writeText(url);

        $icon.removeClass('icon-link').addClass('icon-check');

        setTimeout(() => {
            $icon.removeClass('icon-check').addClass('icon-link');
        }, 1000);
    });

});
