jQuery(document).ready(function($){

    function wm_pluralize(num) {
        let num10 = num % 10;
        let num100 = num % 100;

        if (num == 1) {
            return 0;
        } else if ((num10 >= 2) && (num10 <= 4) && ((num100 < 10) || (num100 >= 20))) {
            return 1;
        } else {
            return 2;
        }
    }

    function wm_parse_timestamp(timestamp) {
        timestamp = parseInt(timestamp);

        let current_time = Math.floor(Date.now() / 1000);
        timestamp -= current_time;

        let values = {};

        let plurals = {
            days: ['dzień', 'dni', 'dni'],
            hours: ['godzina', 'godziny', 'godzin'],
            minutes: ['minuta', 'minuty', 'minut'],
            seconds: ['sekunda', 'sekundy', 'sekund'],
        };

        if (timestamp < 0) {
            values = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };

            return values;
        }

        let keys = {
            days: 60 * 60 * 24,
            hours: 60 * 60,
            minutes: 60,
            seconds: 1,
        };

        for (const [key, interval] of Object.entries(keys)) {
            let val = Math.floor(timestamp / interval);
            timestamp -= val * interval;
            let label = plurals[key][wm_pluralize(val)];
            values[key] = {
                value: val,
                label: label,
            };
        }

        return values;
    }

    function wm_refresh_countdown() {
        $('[data-countdown]').each(function() {
            let timestamp = $(this).data('countdown');

            if (timestamp === undefined) {
                return;
            }

            let values = wm_parse_timestamp(timestamp);

            for (const [key, val] of Object.entries(values)) {
                let $num = $(this).find(`[data-${key}-value]`);
                let $lab = $(this).find(`[data-${key}-label]`);

                if ($num === undefined || val.value === undefined) {
                    continue;
                }

                $num.text(String(val.value).padStart(2, '0'));
                $lab.text(val.label);
            }

        });
    }

    if ($('[data-countdown]').length) {
        wm_refresh_countdown();

        setInterval(wm_refresh_countdown, 1000);
    }

});
