function wm_init_accordions() {
    let accordions = document.querySelectorAll('[data-accordion-toggle]');

    if (!accordions.length) {
        return;
    }

    accordions.forEach(el => {
        el.addEventListener('click', function() {
            this.closest('[data-accordion-parent]').classList.toggle('is-active');
            return false;
        });
    });
}

wm_init_accordions();
