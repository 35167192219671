jQuery(document).ready(function($){
    let $section = $('.section-tiles');

    function isElementVisible(el) {
        const rect = el.getBoundingClientRect();
        const viewportHeight = $(window).height();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        // Check if element is completely above or below viewport
        return (elemTop < viewportHeight && elemBottom > 0);
    }

    function isElementAbove(el) {
        const rect = el.getBoundingClientRect();

        // Check if element is completely below viewport
        return (rect.top < 0);
    }

    function getVisiblePercentage(el) {
        if (isElementAbove(el)) {
            return 1;
        }

        if (!isElementVisible(el)) {
            return 0;
        }

        const rect = el.getBoundingClientRect();
        const viewportHeight = $(window).height();
        const elemTop = rect.top;
        const elemHeight = rect.height;

        // Percentage of element visible within viewport
        const visibleHeight = Math.min(elemHeight, viewportHeight - elemTop);
        const visibleRatio = (visibleHeight / elemHeight);

        return visibleRatio;
    }

    function tiles_slide_anim() {
        let $items = $('[data-animation="tiles-slide"]');
        let intersectionRatio = getVisiblePercentage($items[0]);
        $section[0].style.setProperty('--intersection-ratio', intersectionRatio);
    }

    if ($section.length) {
        $(document).scroll(Foundation.util.throttle(tiles_slide_anim, 200));
    }

});

