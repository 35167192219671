{
    let html_style = getComputedStyle(document.querySelector('html'));
    let scroll_padding = html_style.getPropertyValue('--scroll-padding');

    let observer = new IntersectionObserver(
        ([e]) => {
            let sel = e.target.getAttribute('id');
            let parent = document.querySelector(`[data-scroll-target=${sel}]`);

            if (parent == null) {
                return;
            }

            let siblings = [...parent.parentNode.children].filter((child) => child !== parent);

            siblings.forEach((el) => {
                el.classList.remove('is-active');
            });

            parent.classList.toggle('is-active', e.isIntersecting);
        },
        {
            threshold: [0],
            root: null,
            rootMargin: `-${scroll_padding}`,
        }
    );

    let targets = document.querySelectorAll('[data-scroll-target]');

    if (targets !== null) {
        targets.forEach((el) => {
            let sel = el.getAttribute('data-scroll-target');
            let parent = document.querySelector('#' + sel);

            if (parent == null) {
                return;
            }

            observer.observe(parent);
        });
    }
}

jQuery(document).ready(function($){

    function wm_sticky_button() {
        $('[data-sticky-button]').each(function() {
            let sel = $(this).data('sticky-button');

            if (sel === undefined) {
                return;
            }

            let $target = $(`#${sel}`);

            if (!$target.length) {
                return;
            }

            let current_scroll = $(window).scrollTop();
            let is_visible = $target.visible();
            let scrolled_past = current_scroll > $target.offset().top;

            $('[data-sticky-button]').toggleClass('is-stuck', !is_visible && scrolled_past);
        });
    }

    if ($('[data-sticky-button]').length) {
        $(window).scroll(Foundation.util.throttle(wm_sticky_button, 200));

        wm_sticky_button();
    }

});
