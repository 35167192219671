jQuery(document).ready(function($){
    function wm_calculator() {
        var price = document.getElementById('price').value;
        var activeRadio = $('input[name="toggler--ultra"]:checked').val();

        var summary = parseInt((price * 0.1) * activeRadio);
        var monthlySummary = parseInt((price * 0.1));

        $('.profit').text(monthlySummary);
        $('.final__price').html('<span>' + summary + '</span> zł');
        $('.calculator__text').html(`Tyle zyskasz dzięki poleceniu nam firmy na okres <span>${activeRadio} miesięcy</span> za kwotę <span>${price} zł</span> miesięcznie.`);
    }

    if ($('.calculator').length) {
        wm_calculator();

        $(".calculator .price").on('input', function () {
            wm_calculator();
        });

        $('.calculator input[type=radio][name=toggler--ultra]').change(wm_calculator);
    }

});
