jQuery(document).ready(function($){

    function wm_window_resize() {
        let header_height = $('.header').innerHeight();
        document.querySelector('html').style.setProperty('--scroll-padding', `${header_height}px`);
    }

    $(window).resize(Foundation.util.throttle(wm_window_resize, 200));

});
