const sticky_observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle("is-stuck", e.intersectionRatio < 1) ,
    {
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
    }
);

const el = document.querySelector("[data-sticky]");

if (el !== null) {
    sticky_observer.observe(el);
}

var wm_scroll_var, wm_current_scroll_position;

jQuery(document).ready(function($){

    function wm_sticky_nav() {
        wm_current_scroll_position = $(this).scrollTop();
        $('body').toggleClass('nav-is-visible', wm_scroll_var > wm_current_scroll_position);
        wm_scroll_var = wm_current_scroll_position;
    }

    $(window).scroll(Foundation.util.throttle(wm_sticky_nav, 200));

});
