jQuery(document).ready(function($){
    if ($('[data-slider]').length) {
        $('[data-slider]').each(function() {
            let slider_opts = {
                dots: false,
                speed: 300,
                infinite: true,
                autoplay: false,
                fade: false,
                autoplaySpeed: 5000,
                cssEase: 'ease-out',
                arrows: true,
                prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-right"></i></button>',
            };

            let slider_style = $(this).data('slider-style') || 'medium';

            if (slider_style === 'medium') {
                slider_opts.slidesToShow = 3;

                slider_opts.responsive = [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ];
            } else if (slider_style === 'thin') {
                slider_opts.slidesToShow = 4;

                slider_opts.responsive = [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ];
            } else if (slider_style === 'ultra-thin') {
                slider_opts.slidesToShow = 5;

                slider_opts.responsive = [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ];
            } else if (slider_style === 'two') {
                slider_opts.slidesToShow = 2;

                slider_opts.responsive = [
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ];
            } else if (slider_style === 'variable') {
                slider_opts.dots = false;
                slider_opts.arrows = false;
                slider_opts.infinite = false;
                slider_opts.variableWidth = true;
                slider_opts.swipeToSlide = true;
                slider_opts.outerEdgeLimit = true;
                slider_opts.responsive = false;
            } else if (slider_style == 'centered') {
                slider_opts.dots = false;
                slider_opts.arrows = false;
                slider_opts.infinite = true;
                slider_opts.swipeToSlide = true;
                slider_opts.outerEdgeLimit = true;
                slider_opts.centerMode = true;
                slider_opts.slidesToShow = 5;

                slider_opts.responsive = [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ];
            } else if (slider_style === 'single') {
                slider_opts.slidesToShow = 1;
                slider_opts.slidesToScroll = 1;
                slider_opts.responsive = false;
            }

            let $slider_parent = $(this).parents('[data-slider-parent]');

            if ($slider_parent.length) {
                let arrows = $slider_parent.find('[data-slider-arrows]');

                if (arrows !== undefined) {
                    slider_opts.appendArrows = arrows;
                }
            }

            $(this).slick(slider_opts);
        });
    }

});
