jQuery(document).ready(function($){

    $('[data-modal-btn]').click(function() {
        $(this).toggleClass('is-active');
        let target = '#' + $(this).data('modal-btn');
        $(target).toggleClass('is-active');
    });

    $('[data-modal-open]').click(function() {
        let sel = '#' + $(this).data('modal-open');
        let el = document.querySelector(sel);

        if (!el) {
            return;
        }

        el.showModal();

        let video = el.querySelector('video');
        if (video) {
            video.play();
        }

        let iframe = el.querySelector('iframe');
        if (iframe) {
            iframe = iframe.contentWindow;
            let vidFunc = 'playVideo';
            iframe.postMessage(
                '{"event":"command","func":"' + vidFunc + '","args":""}',
                "*"
            );
        }
    });

    $('[data-modal-close]').click(function() {
        let $parent = $(this).closest('dialog');

        if (!$parent) {
            return;
        }

        let video = $parent.find('video');
        if (video.length) {
            if (!video[0].paused) {
                video[0].pause();
            }
        }

        let iframe = $parent.find('iframe');
        if (iframe.length) {
            iframe = iframe[0].contentWindow;
            let vidFunc = 'pauseVideo';
            iframe.postMessage(
                '{"event":"command","func":"' + vidFunc + '","args":""}',
                "*"
            );
        }

        $parent[0].close();
    });

});
