jQuery(document).ready(function($){

  $('.pagination__form input').on('change', function(){
    var inputVal = $(this).val();

    if(inputVal != 1) {
      $('.pagination__form').attr('action', wm_vars.pagenum_link + 'page/' + inputVal + '/');
    } else {
      $('.pagination__form').attr('action', wm_vars.pagenum_link);
    }
  });

});
